import {
    CalendarIcon,
    ChartBarIcon,
    CheckCircleIcon,
    CogIcon,
    DatabaseIcon,
    DotsVerticalIcon,
    ExclamationIcon,
    FolderIcon,
    HomeIcon,
    InboxIcon,
    LogoutIcon,
    SearchIcon,
    MenuIcon,
    PresentationChartLineIcon,
    StarIcon,
    UsersIcon,
    XCircleIcon,
    XIcon,
    PencilAltIcon,
    InformationCircleIcon,
} from '@heroicons/vue/outline';

import {
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    HomeIcon as HomeIconSolid,
    StarIcon as StarIconSolid,
    CheckIcon as CheckIconSolid,
    SelectorIcon as SelectorIconSolid,
    DatabaseIcon as DatabaseIconSolid,
    PlusSmIcon as PlusSmIconSolid,
    PlusCircleIcon as PlusCircleIconSolid,
    TrashIcon as TrashIconSolid,
    ExclamationIcon as ExclamationIconSolid,
} from '@heroicons/vue/solid';

import { createApp } from 'vue';
import Notifications from '@kyvg/vue3-notification';
import VueClickAway from 'vue3-click-away';
import i18n from '@/i18n';
import App from '@/App.vue';
import '@/index.css';

const vueAppInstance = createApp(App)
    .use(i18n)
    .use(Notifications)
    .use(VueClickAway);

try {
    // initialize oauth client
    await import('@/clients/oauthClient');

    const [{ default: store }, { default: router }] = await Promise.all([import('@/store'), import('@/router')]);

    vueAppInstance
        .use(store)
        .use(router);
} catch (error) {
    const { default: appInitializationErrorRouter } = await import('@/router/initializationErrorRouter');
    vueAppInstance.use(appInitializationErrorRouter);
}

vueAppInstance.component('ArrowNarrowLeftIcon', ArrowNarrowLeftIcon)
    .component('ArrowNarrowRightIcon', ArrowNarrowRightIcon)
    .component('CalendarIcon', CalendarIcon)
    .component('ChartBarIcon', ChartBarIcon)
    .component('CheckIconSolid', CheckIconSolid)
    .component('DatabaseIcon', DatabaseIcon)
    .component('DatabaseIconSolid', DatabaseIconSolid)
    .component('CheckCircleIcon', CheckCircleIcon)
    .component('ChevronDownIcon', ChevronDownIcon)
    .component('ChevronLeftIcon', ChevronLeftIcon)
    .component('ChevronRightIcon', ChevronRightIcon)
    .component('ChevronUpIcon', ChevronUpIcon)
    .component('CogIcon', CogIcon)
    .component('DotsVerticalIcon', DotsVerticalIcon)
    .component('ExclamationIcon', ExclamationIcon)
    .component('FolderIcon', FolderIcon)
    .component('HomeIcon', HomeIcon)
    .component('HomeIconSolid', HomeIconSolid)
    .component('InboxIcon', InboxIcon)
    .component('LogoutIcon', LogoutIcon)
    .component('MenuIcon', MenuIcon)
    .component('PresentationChartLineIcon', PresentationChartLineIcon)
    .component('SearchIcon', SearchIcon)
    .component('SelectorIconSolid', SelectorIconSolid)
    .component('StarIcon', StarIcon)
    .component('StarIconSolid', StarIconSolid)
    .component('TrashIconSolid', TrashIconSolid)
    .component('UsersIcon', UsersIcon)
    .component('XCircleIcon', XCircleIcon)
    .component('XIcon', XIcon)
    .component('PencilAltIcon', PencilAltIcon)
    .component('PlusSmIconSolid', PlusSmIconSolid)
    .component('PlusCircleIconSolid', PlusCircleIconSolid)
    .component('ExclamationIconSolid', ExclamationIconSolid)
    .component('InformationCircleIcon', InformationCircleIcon);

vueAppInstance.mount('#app');
