import { createI18n, I18nOptions } from 'vue-i18n';
import { merge } from 'ts-deepmerge';
import messagesEn from '@/i18n/en.json';
import messagesDe from '@/i18n/de.json';
import dashboardsEn from '@/i18n/dashboards/en.json';
import dashboardsDe from '@/i18n/dashboards/de.json';
import dashboardConfigEn from '@/i18n/dashboardConfig/en.json';
import dashboardErrorsEn from '@/i18n/dashboardErrors/en.json';
import dashboardErrorsDe from '@/i18n/dashboardErrors/de.json';

type messageTranslations = {
    [key: string]: string;
}

const allMessagesEn = merge(messagesEn as messageTranslations, dashboardErrorsEn as messageTranslations, dashboardsEn, dashboardConfigEn);
const allMessagesDe = merge(messagesDe as messageTranslations, dashboardErrorsDe as messageTranslations, dashboardsDe);

export const messages = {
    en: allMessagesEn,
    de: allMessagesDe,
};

export const defaultLocale = 'en';

export function getLanguageFromLocale(locale: string): string | null {
    if (locale.length === 2) {
        return locale.toLocaleLowerCase();
    }
    let parts = locale.split('-');
    if (parts.length > 0) {
        if (parts[0].length === 2) {
            return parts[0].toLocaleLowerCase();
        }
    }
    parts = locale.split('_');
    if (parts.length > 0) {
        if (parts[0].length === 2) {
            return parts[0].toLocaleLowerCase();
        }
    }
    return null;
}

// 2. Create i18n instance with options
export const i18nOptions: I18nOptions = {
    legacy: false,
    locale: defaultLocale, // set locale
    fallbackLocale: defaultLocale, // set fallback locale
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
    warnHtmlMessage: false,
};

const i18n = createI18n(i18nOptions);

export default i18n;
