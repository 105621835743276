<template>
  <router-view />
  <notifications
    position="bottom right"
    width="100%"
    :dangerously-set-inner-html="true"
  >
    <template #body="props">
      <!-- Global notification live region, render this permanently at the end of the document -->
      <div
        aria-live="assertive"
        class="inset-0 flex items-end px-4 py-6 sm:p-6 sm:items-start">
        <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
          <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
          <transition
            enter-active-class="ease-out duration-300 transition"
            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <!-- eslint-disable -->
            <div class="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div class="p-4">
                <div class="flex items-start">
                  <div class="shrink-0" v-if="props.item.type === 'success'">
                    <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
                  </div>
                  <div class="shrink-0" v-else>
                    <XCircleIcon class="h-6 w-6 text-red-700" aria-hidden="true" />
                  </div>
                  <div class="ml-3 w-0 flex-1 pt-0.5">
                    <p
                      class="text-sm font-medium text-gray-900"
                      data-name="notificationText"
                      v-html="props.item.text"
                    />
                  </div>
                  <div class="ml-4 shrink-0 flex">
                    <button
                      type="button"
                      @click="props.close"
                      class="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span class="sr-only">Close</span>
                      <XIcon class="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- eslint-enable -->
          </transition>
        </div>
      </div>
    </template>
  </notifications>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'App',
});
</script>
